.paxpad{padding: .5rem 3px 0 !important;}
.product-card{
    background: #f0e982;
    background-color: rgb(240, 233, 130);
  background-color: rgb(240, 233, 130);
  padding: 5px 15px 5px 15px;
  border-radius: 8px;
  height: 130px;
  margin-bottom: 0px;
  position: relative;
  font-family: "regular";
}
.i{margin-left: 2px;}
.product-name{font-size: 16px;
  font-weight: bold;
    color: black;
    text-align: left;
    height: 34px;
    line-height: 18px;}
    .pricebox{
        position: absolute;
  background: #e15b5b;
  padding: 5px 5px;
  bottom: 0px;
  right: 0px;
  font-size: 15px;
  border-radius: 22px 0 8px 0;
  color: white;
  width: 100px;
  text-align: center;
    }
    .product-img {
        width: 75px;
        height: 75px;
        margin: 0px 0 0px -10px;
        display: flex;
      }
      .product-img img{
        display: flex;
        object-fit: contain;
      }
      .left-margin{
        margin-left: 30px;
      }
    .net_rate{
        color: red;
        text-decoration: line-through;
        font-weight: 600;
        font-family: "regular";
    }
    .play-icon {
        font-size: 25px;
        background-color: red;
        height: 60px;
        width: 92px !important;
        line-height: 52px;
        text-align: center !important;
        color: #fff;
        border-radius: 8px;
        padding: 0 36px;
      }
      .modal-img img {
        width: 220px;
        height: 220px;
        display: flex;
        margin: 0 auto;
      }
.discount_rate,.no_net_rate{
    color: green;
    font-weight: 600;
     font-family: "regular";
}
/* cart start */
.cart-product-image img{
  width: 100px;
}
/* .qty-td{width: 75px !important;} */
.cart-board{
    flex: 1 1 1e-9px;
    display: flex;
    place-content: center space-between;
    align-items: center;
    background: white;
    padding: 4px 1px;
    margin: 0;
    border-bottom: 1px dotted silver;
    font-size: 13px
}
.cart-product-img{
  max-width: 40px;
  max-height: 40px;
}
.cart-product-name{
  width: 140px;
}
.cart-input{
    width: 40px !important;
    padding: revert !important;
    height: auto !important;
    margin-top: 3px !important;
}
.cart-rate{
  font-size: 12px;
  padding-right: 5px;
  color: red !important;
}

  /* cart end */