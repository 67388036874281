@font-face {
  font-family: "regular";
  src: url("./assets/fonts/Jost-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "bold";
  src: url("./assets/fonts/Merienda-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Merienda&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Merienda&family=Roboto&display=swap");

/* body{
 background: url(./assets/images/bodybackground.jpg) no-repeat fixed top center;
background-size: cover;
} */
.lg-font {
  font-size: 50px !important;
}
.chutti {
  mix-blend-mode: darken;
}
.regular {
  font-family: "regular";
}
.bold {
  font-family: "bold";
}
/* default start */
.overflow-hide {
  overflow: hidden;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.over {
  overflow: hidden;
}
.padding {
  padding: 90px 0;
}
.foot-pad {
  padding: 30px 0;
}
.navv-bg {
  background-color: white !important;
  --background: url(./assets/images/crackergif.gif);
  background-position: center center;
  background-repeat: repeat;
  height: auto;
}
.logo {
  width: 350px;
}
.over {
  overflow: hidden;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 10px 40px !important;
  font-family: "bold";
  font-size: 17px;
}
.mob-icon {
  width: 50px;
}
.navbar-nav .nav-link.active {
  color: #fff !important;
  border-radius: 50px 0;
  background-color: #002c5b;
}
.fullpad {
  padding: 0 !important;
  margin: 0 !important;
}
.bg-overlay {
  position: relative;
}
.bg-overlay::before {
  background: #fc6a21e3 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.z {
  position: relative;
  z-index: 2;
}
/* default end */
/* app bar start */
.nav-bg {
  background-color: #395aa8 !important;
  display: inline;
  padding: 10px;
  margin: 12px 0;
  border-radius: 20px 0;
  color: #fff;
}
.top-bg {
  background-image: url("./assets/images/topbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}
.top-bg::before {
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.top-info li p {
  display: inline-block;
  margin-bottom: 8px;
}
.top-info li svg {
  font-size: 20px;
  margin-right: 16px;
}
#home {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: 50% 0px;
}
.home-center {
  height: 100vh;
  vertical-align: middle;
  display: table-cell;
  position: relative;
}
.home-center img {
  width: 200px;
  margin: 0 auto;
}
.down svg {
  color: #ffffff;
  font-size: 55px;
}
.quote {
  font-size: 55px;
  color: #fff;
  font-family: "regular";
}
.mark-bg {
  background-color: #fc6a21;
  color: #1f1d1d;
  font-family: "regular";
}
.gpay {
  width: 185px;
}
.estimate {
  background: #6482ad !important;
  padding: 10px !important;
  font-family: "bold" !important;
  color: #fff !important;
}
/* app bar end */
/* footer start */
.list-unstyled li {
  padding: 12px 0;
}
.list-unstyled li a {
  text-decoration: none !important;
  color: #ffffff !important;
  font-family: "regular";
}
.rules {
  line-height: 35px;
  font-family: "regular";
}
.footer-bg {
  background-color: #001b5a;
  font-family: "regular";
  color: #ffffff;
}
.ft-logo {
  width: 150px;
}
.ft-icon {
  background: #ff6a00;
  padding: 12px;
  border-radius: 50%;
  color: #141313;
  font-size: 32px;
  line-height: 10px;
}
.ft-details {
  margin: 0 20px;
}
.text_one {
  position: relative;
  padding: 5px 0 0 30px;
  margin-top: -42px;
}
/* footer end */
/* site off start */
.site-off {
  background-image: url(./assets/images/siteoffbanner.jpg);
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.site-off::before {
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.site-of-text h2 {
  position: relative;
  z-index: 2;
}
.site-of-text {
  position: relative;
  z-index: 2;
}
.site-of {
  width: 25%;
}
/* .headnav{
  background-color: rgb(210 46 55) !important
} */
.subheadcolor {
  font-family: "Merienda", cursive;
  color: #00008a;
}
.h1color {
  color: darkblue;
}

.pad-z {
  margin-bottom: 2px !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.why-box-one {
  background-color: #f5ebeb;
  padding: 30px;
  height: 220px;
}
.why-box-two {
  background-color: #dcebdd;
  padding: 30px;
  height: 220px;
}

.ft-logo img {
  background-color: white; /* White background */
  border-radius: 50%; /* Rounded Circle */
  padding: 10px; /* Space between the logo and the border */
  border: 2px solid #ccc; /* Optional border */
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Ensure the height matches the width for a perfect circle */
  object-fit: contain; /* Ensure the image is contained within the circle */
}

.text_one {
  font-size: 14px;
}

.smallfnt {
  font-size: 13px;
}
